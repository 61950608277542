import React, { useEffect, useState } from 'react'
import { getVehicleAuctionSblu } from '../../service/auctionVehicleDataService'
import { getPossibleFeeSchedules } from '../../service/feeScheduleService'
import { VehicleNotesTable } from '../VehicleNotesTable'
import { PossibleFeeTable } from '../tables/PossibleFeeTable'
import { CalculatedFeeTable } from '../tables/CalculatedFeeTable'
import { AuctionVehicleDataTable } from '../tables/AuctionVehicleDataTable'
import { ManualFeesForm } from '../forms/ManualFeesForm'
import { Button } from '@interstate/components/Button'
import {
    ArrowPathIcon,
    ArrowUturnLeftIcon,
    PlayCircleIcon
} from '@interstate/components/Icons'
import { postFeeEvent } from '../../service/manualService'
import { PatWarningAlert } from '../common/PatWarningAlert'

export function IndividualAudit({ data, back }) {
    const [initData, setInitData] = useState(null)
    const [vehicleData, setVehicleData] = useState({})
    const [feeSchedules, setFeeSchedules] = useState([])
    const [highlightedRow, setHighlightedRow] = useState([])
    const [refresh, setRefresh] = useState(new Date().getTime())

    useEffect(() => {
        if (data && data.length > 0) {
            setInitData(data[0])
        }
    }, [data])

    useEffect(() => {
        if (!initData) return
        setInitData(data[0])
        let av = initData?.buCode
        let sblu = initData?.sblu
        getVehicleAuctionSblu(av, sblu)
            .then((d) => {
                setVehicleData(d)
            })
            .catch((error) => {
                setVehicleData(error.response.data)
            })

        let highlight = []

        data.forEach((fee) => {
            if (!highlight.includes(fee.feeSchedulePartId)) {
                highlight.push(fee.feeSchedulePartId)
            }
        })
        setHighlightedRow(highlight)
    }, [initData, data, refresh])

    useEffect(() => {
        if (!initData) return

        let av = initData?.buCode

        let customerIds = [
            initData?.seller,
            initData?.buyer,
            initData?.sellerGroup,
            initData?.buyerGroup,
            initData?.sellerSubGroup,
            initData?.buyerSubGroup
        ]
        if (vehicleData?.body?.saleDate) {
            getPossibleFeeSchedules(
                av,
                customerIds,
                initData.saleType,
                vehicleData?.body?.saleDate
            )
                .then((d) => {
                    setFeeSchedules(d)
                })
                .catch((error) => {
                    setFeeSchedules(error?.response?.data)
                })
        }
    }, [initData, vehicleData])
    // console.log(vehicleData)
    const replay = () => {
        const event = {
            bucode: initData?.buCode,
            sblu: initData?.sblu,
            workOrder: initData?.workOrderNumber,
            vin: vehicleData?.body?.vin,
            seller: vehicleData?.body?.seller,
            buyer: vehicleData?.body?.buyer
        }
        let error = false
        Object.keys(event).forEach((key) => {
            if (event[key] === null || event[key] === undefined) {
                error = true
                console.log('error missing event attribute: ', key)
            }
        })

        if (!error) {
            postFeeEvent(event)
        }
    }

    return (
        <>
            <div>
                <Button
                    buttonStyle="tertiary"
                    data-testid={'back-icon'}
                    onClick={back}
                    startIcon={<ArrowUturnLeftIcon />}>
                    Back
                </Button>
                {vehicleData?.body?.buyerFinalize !== 'Y' && (
                    <>
                        <Button
                            buttonStyle="tertiary"
                            data-testid={'refresh-icon'}
                            onClick={() => {
                                setRefresh(new Date().getTime())
                            }}
                            startIcon={<ArrowPathIcon />}>
                            Refresh
                        </Button>
                        <Button
                            buttonStyle="tertiary"
                            data-testid={'replay-icon'}
                            onClick={replay}
                            startIcon={<PlayCircleIcon />}
                            aria-label="Replay Event"
                            title="Replay Event">
                            Replay
                        </Button>
                    </>
                )}
            </div>
            <div>
                <PatWarningAlert
                    error={vehicleData?.body?.buyerFinalize === 'Y'}
                    title="Buyer Finalized"
                    text="Adjustments must be posted via Workbench or directly in Oracle."
                />
            </div>
            <AuctionVehicleDataTable
                initData={initData}
                vehicleData={vehicleData}
            />

            <CalculatedFeeTable
                sblu={initData?.sblu}
                bucode={initData?.buCode}
                refresh={refresh}
            />

            {vehicleData?.body?.buyerFinalize !== 'Y' && (
                <ManualFeesForm
                    bucode={initData?.buCode}
                    sblu={initData?.sblu}
                    workOrder={initData?.workOrderNumber}
                    vin={vehicleData?.body?.vin}
                    buyerNumber={vehicleData?.body?.buyer}
                    sellerNumber={vehicleData?.body?.seller}
                    salePrice={vehicleData?.body?.salePrice}
                    saleType={initData?.saleType}
                    channel={vehicleData?.body?.saleChannel}
                    feeData={data}
                />
            )}

            <h2>Eligible Seller Fees</h2>
            <PossibleFeeTable
                fees={feeSchedules}
                filterCriteria={['SSELL', 'SELLER', 'SLIST']}
                highlightedRow={highlightedRow}
            />

            <h2>Eligible Buyer Fees</h2>
            <PossibleFeeTable
                fees={feeSchedules}
                filterCriteria={[
                    'BUYER',
                    'BLIST',
                    'BBUY',
                    'SELLBUY',
                    'SELLBYS'
                ]}
                highlightedRow={highlightedRow}
            />

            {vehicleData?.body?.saleChannel === 'Z' && (
                <>
                    <h2>Eligible Simulcast Fees</h2>
                    <PossibleFeeTable
                        fees={feeSchedules}
                        filterCriteria={[
                            'INTBUY',
                            'INTSELL',
                            'SIMBFE',
                            'SIMBUY'
                        ]}
                        highlightedRow={highlightedRow}
                    />
                </>
            )}

            {initData && (
                <>
                    <h2>Vehicle Notes</h2>
                    <VehicleNotesTable
                        auction={initData?.buCode}
                        workOrderNumber={initData.workOrderNumber}
                    />
                </>
            )}
        </>
    )
}
