import moment from 'moment'
import {
    isEmptyString,
    isEmptyStringArray,
    isNumeric
} from './commonValidations'
import { renderDateFromIsoString, renderDateFromString } from './Formatters'
import { FEE_SCHEDULE_TYPE } from '../resources/enum.constants'

export const RequiredCustomerIds = [
    'Contract Overrides',
    'National Contracts',
    'Local Deals',
    'Contracts Below Local Deals'
]

export function customerIdsRequired(feeScheduleType) {
    return RequiredCustomerIds.includes(feeScheduleType)
}

export function validateFeeSchedule(feeScheduleModel) {
    let newErrorMessages = {}

    if (!feeScheduleModel.startDate) {
        newErrorMessages.startDate = 'Start Date is required'
    } else if (
        !!feeScheduleModel.endDate &&
        moment(feeScheduleModel.startDate).isAfter(
            moment(feeScheduleModel.endDate)
        )
    ) {
        newErrorMessages.startDate = 'Start Date must be before End Date.'
    }

    if (
        feeScheduleModel.feeScheduleType !==
            FEE_SCHEDULE_TYPE.GLOBAL_OVERRIDES &&
        feeScheduleModel.feeScheduleType !== FEE_SCHEDULE_TYPE.DEFAULTS
    ) {
        if (
            (isEmptyString(feeScheduleModel.accountNumbers) ||
                isEmptyStringArray(feeScheduleModel.accountNumbers)) &&
            (isEmptyString(feeScheduleModel.groupCodes) ||
                isEmptyStringArray(feeScheduleModel.groupCodes)) &&
            (isEmptyString(feeScheduleModel.altGroupCodes) ||
                isEmptyStringArray(feeScheduleModel.altGroupCodes))
        ) {
            newErrorMessages.customerIds =
                'At least one account, group code, or alternate group code is required.'
        }
    }
    // if(!feeScheduleModel.customerIds?.length && customerIdsRequired(feeScheduleModel.feeScheduleType)){
    //     newErrorMessages.customerIds = 'At least one Group Code or Account # is required.'
    // }

    if (isEmptyString(feeScheduleModel.description)) {
        newErrorMessages.description = 'Description is required.'
    }

    if (feeScheduleModel.description?.length > 254) {
        newErrorMessages.description =
            'Description can be maximum 254 characters.'
    }

    if (!feeScheduleModel.saleTypeFeeList?.length) {
        newErrorMessages.saleTypeFeeList =
            'At least one Sale Type and Fee Type must be specified.'
    } else if (
        feeScheduleModel.saleTypeFeeList.some(
            (stf) =>
                !stf.saleType ||
                !stf.feeCode ||
                !stf.auctionCodeList?.length ||
                !stf.feeStructure
        )
    ) {
        newErrorMessages.saleTypeFeeList =
            'Every section must specify a Sale Type, Fee Type, one or more Auction Locations, and a Rate Card.'
    }

    // validate for all the overrides/exceptions
    if (feeScheduleModel.saleTypeFeeList?.length > 0) {
        feeScheduleModel.saleTypeFeeList.forEach((saleTypeFee, index) => {
            if (saleTypeFee.overrides?.length > 0) {
                if (
                    saleTypeFee.overrides.some(
                        (stf) =>
                            !stf.auctionCodeList?.length || !stf.feeStructure
                    )
                ) {
                    newErrorMessages.saleTypeFeeList =
                        'Every exception must specify an Auction Locations, and a Rate Card.'
                }
            }
        })
    }

    return newErrorMessages
}

export function validateSearchRateCard({
    numTiers,
    startFee,
    tier2Fee,
    tier3Fee,
    tier4Fee
}) {
    let newErrorMessages = {}

    if (isEmptyString(numTiers)) {
        newErrorMessages.numTiers = 'Tiers is required.'
    } else if (!isNumeric(numTiers)) {
        newErrorMessages.numTiers = 'Tiers must be numeric.'
    }

    if (isEmptyString(startFee)) {
        newErrorMessages.startFee = 'Start Fee is required.'
    } else if (!isNumeric(startFee)) {
        newErrorMessages.startFee = 'Start Fee must be numeric.'
    }

    if (!isEmptyString(tier2Fee) && !isNumeric(tier2Fee)) {
        newErrorMessages.tier2Fee = 'Tier 2 Fee must be numeric.'
    }

    if (!isEmptyString(tier3Fee) && !isNumeric(tier3Fee)) {
        newErrorMessages.tier3Fee = 'Tier 3 Fee must be numeric.'
    }

    if (!isEmptyString(tier4Fee) && !isNumeric(tier4Fee)) {
        newErrorMessages.tier4Fee = 'Tier 4 Fee must be numeric.'
    }

    return newErrorMessages
}

export function getCSVHeader(maxTiers) {
    const csvHeaderKeys = [
        { key: 'buCode', label: 'Location' },
        { key: 'customer', label: 'Customer' },
        { key: 'feeScheduleType', label: 'Fee Schedule Type' },
        { key: 'saleType', label: 'Sale Type' },
        { key: 'feeType', label: 'Fee Type' },
        { key: 'startDate', label: 'Start Date' },
        { key: 'endDate', label: 'End Date' },
        { key: 'createdTime', label: 'Created On' },
        { key: 'createdBy', label: 'Created By' },
        { key: 'description', label: 'Description' },
        { key: 'adderType', label: 'Adder' },
        { key: 'salesPriceLimit', label: 'Adder Starts' },
        { key: 'addFeeAmount', label: 'ADD Amount' },
        { key: 'feeUnit', label: 'ADD For Each' },
        { key: 'feePercent', label: 'MULTIPLY Percentage' },
        { key: 'feeMaximum', label: 'Maximum Charge' },
        { key: 'feeStructureType', label: 'Flat/Tiered' }
    ]

    for (let iLoop = 1; iLoop <= maxTiers; iLoop++) {
        csvHeaderKeys.push({
            key: `highLimit${iLoop}`,
            label: `High Limit ${iLoop}`
        })
        csvHeaderKeys.push({ key: `fee${iLoop}`, label: `Fee ${iLoop}` })
    }
    return csvHeaderKeys
}

export function getFeeScheduleFlatForCSV(feeScheduleFlatList) {
    if (feeScheduleFlatList && feeScheduleFlatList.length > 0) {
        let maxTiers = 0
        let feeSchedeuleFlatListForCsv = []
        feeScheduleFlatList.forEach((feeScheduleFlat) => {
            let feeScheduleFlatForCsv = {
                buCode: feeScheduleFlat.buCode,
                customer: feeScheduleFlat.customer,
                feeScheduleType: feeScheduleFlat.contractType,
                saleType: feeScheduleFlat.saleType,
                feeType: feeScheduleFlat.feeType,
                startDate: renderDateFromString(
                    feeScheduleFlat.startDate,
                    'YYYY-MM-DD',
                    'MM-DD-YYYY'
                ),
                endDate: renderDateFromString(
                    feeScheduleFlat.endDate,
                    'YYYY-MM-DD',
                    'MM-DD-YYYY'
                ),
                createdTime: renderDateFromIsoString(
                    feeScheduleFlat.createdTime,
                    'MM-DD-YYYY'
                ),
                createdBy: feeScheduleFlat.createdBy,
                description: feeScheduleFlat.description,
                adderType: '',
                salesPriceLimit: feeScheduleFlat.feeStructure.salesPriceLimit,
                addFeeAmount: feeScheduleFlat.feeStructure.addFeesAmount,
                feeUnit: feeScheduleFlat.feeStructure.feeUnit,
                feePercent: feeScheduleFlat.feeStructure.feePercent,
                feeMaximum: feeScheduleFlat.feeStructure.feeMaximum,
                feeStructureType: feeScheduleFlat.feeStructure.feeStructureType
            }

            // get adder Type
            if (
                isNumeric(feeScheduleFlat.feeStructure.feePercent) &&
                feeScheduleFlat.feeStructure.feePercent > 0
            ) {
                feeScheduleFlatForCsv.adderType = 'MULTIPLY'
            } else if (
                isNumeric(feeScheduleFlat.feeStructure.addFeesAmount) &&
                feeScheduleFlat.feeStructure.addFeesAmount > 0
            ) {
                feeScheduleFlatForCsv.adderType = 'ADD'
            }

            // populate tiers
            if (feeScheduleFlat.feeStructure.feeTiers.length > maxTiers) {
                maxTiers = feeScheduleFlat.feeStructure.feeTiers.length
            }

            feeScheduleFlat.feeStructure.feeTiers.forEach((tier, index) => {
                feeScheduleFlatForCsv[`highLimit${index + 1}`] = tier.highLimit
                feeScheduleFlatForCsv[`fee${index + 1}`] = tier.fee
            })

            feeSchedeuleFlatListForCsv.push(feeScheduleFlatForCsv)
        })

        let csvHeaderKeys = getCSVHeader(maxTiers)
        return {
            feeSchedeuleFlatListForCsv,
            csvHeaderKeys
        }
    }

    return []
}
