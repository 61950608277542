import { PatTextInput } from '../common/PatTextInput'
import { Grid } from '@interstate/components/Grid'
import { useEffect, useState } from 'react'
import { Button } from '@interstate/components/Button'
import { Card } from '@interstate/components/Card'
import { TextArea } from '@interstate/components/TextArea'
import {
    postFeeEvent,
    postManualFee,
    removeManualFee,
    useGetManualFees
} from '../../service/manualService'
import { useAuth } from '../../auth/AuthProvider'
import { PAT_ROLE } from '../../resources/enum.constants'
import { PatErrorAlert } from '../common/PatErrorAlert'

function FeeInputForm({
    buyer,
    seller,
    intBuy,
    intSell,
    notes,
    channel,
    onChange
}) {
    return (
        <>
            <Grid container spacing={2}>
                <Grid xs={3}>
                    <PatTextInput
                        label="Buyer"
                        name="buyer"
                        value={buyer}
                        onChange={onChange}
                        type="number"
                    />
                </Grid>
                <Grid xs={3}>
                    <PatTextInput
                        label="Seller"
                        name="seller"
                        value={seller}
                        onChange={onChange}
                        type="number"
                    />
                </Grid>
                <Grid xs={3}>
                    <PatTextInput
                        label="INTBUY"
                        name="intBuy"
                        value={intBuy}
                        onChange={onChange}
                        type="number"
                        disabled={channel !== 'Z'}
                    />
                </Grid>
                <Grid xs={3}>
                    <PatTextInput
                        label="INTSELL"
                        name="intSell"
                        value={intSell}
                        onChange={onChange}
                        type="number"
                        disabled={channel !== 'Z'}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <TextArea
                        label="Notes"
                        name="Notes"
                        maxLength={254}
                        value={notes}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>
        </>
    )
}

function ManualFeeActions({ toggle, setToggle, save, remove }) {
    return (
        <div style={{ margin: '10px' }}>
            <Button
                sx={{
                    marginRight: '10px'
                }}
                onClick={save}>
                Save
            </Button>
            <Button
                sx={{
                    marginRight: '10px',
                    backgroundColor: 'darkred'
                }}
                buttonStyle={'danger'}
                onClick={remove}>
                Remove
            </Button>
            <Button
                buttonStyle={'secondary'}
                onClick={() => {
                    setToggle(!toggle)
                }}>
                Cancel
            </Button>
        </div>
    )
}

export function ManualFeesForm({
    bucode,
    sblu,
    workOrder,
    vin,
    buyerNumber,
    sellerNumber,
    salePrice,
    saleType,
    channel,
    feeData
}) {
    const [toggle, setToggle] = useState(false)
    const [buyer, setBuyer] = useState(null)
    const [seller, setSeller] = useState(null)
    const [intBuy, setIntBuy] = useState(null)
    const [intSell, setIntSell] = useState(null)
    const [notes, setNotes] = useState(null)
    const [validationError, setValidationError] = useState(null)

    const authService = useAuth()
    const principal = authService.principal
    const user = principal?.user
    const role = principal?.role
    const show = role === PAT_ROLE.ADMIN || role === PAT_ROLE.BOC_EDIT

    const {
        value: manualFees,
        loading: manualLoading,
        error: manualError
    } = useGetManualFees(bucode, sblu)

    useEffect(() => {
        if (!manualFees) {
            feeData.forEach((fee) => {
                setValue(fee.feeCategory, fee?.patCalculatedFee)
            })
        }
        let notes = null
        manualFees?.forEach((fee) => {
            setValue(fee.fee_category, fee.amount)

            if (!notes && fee?.description) {
                notes = fee.description
            }
        })
        setNotes(notes)
    }, [manualFees, feeData])

    const onChange = (e) => {
        setValue(e?.target?.name?.toUpperCase(), e.target.value)
    }

    const createDataObject = (attr) => {
        let d = manualFees.filter((fee) => fee.fee_category === attr)
        if (d?.length > 0) {
            return d[0]
        }
        return null
    }

    const getAmount = (attr) => {
        switch (attr) {
            case 'BUYER':
                return buyer
            case 'SELLER':
                return seller
            case 'INTBUY':
                return intBuy
            case 'INTSELL':
                return intSell
            default:
                return null
        }
    }

    const setValue = (attr, value) => {
        switch (attr) {
            case 'BUYER':
                setBuyer(value)
                break
            case 'SELLER':
                setSeller(value)
                break
            case 'INTBUY':
                setIntBuy(value)
                break
            case 'INTSELL':
                setIntSell(value)
                break
            case 'NOTES':
                setNotes(value)
                break
            default:
        }
    }

    const getOriginalFee = (attr) => {
        let d = feeData?.filter((fee) => fee.feeCategory === attr)
        if (d?.length > 0) {
            return { fee: d[0]?.patCalculatedFee, id: d[0].feeSchedulePartId }
        }
        return { fee: null, id: null }
    }

    const onValidate = () => {
        if (notes?.length > 254) {
            setValidationError('Notes cannot exceed 254 characters')
            return false
        }

        setValidationError(null)
        return true
    }

    const onSave = () => {
        if (!onValidate()) {
            return
        }
        let promisedPosts = []
        const attributes = ['BUYER', 'SELLER', 'INTBUY', 'INTSELL']
        attributes.forEach((attr) => {
            if (!getAmount(attr) || getAmount(attr) === '') return
            let data = createDataObject(attr)
            if (data) {
                data.description = notes
                data.amount = getAmount(attr)
            } else {
                const { fee: originalFee, id: orignalFeeId } =
                    getOriginalFee(attr)

                data = {
                    bu_code: bucode,
                    sblu: sblu,
                    fee_category: attr,
                    sale_type: saleType,
                    seller: sellerNumber,
                    buyer: buyerNumber,
                    amount: getAmount(attr),
                    sale_price: salePrice,
                    created_by: user,
                    update_by: user,
                    description: notes,
                    original_amount: originalFee,
                    original_fee_id: orignalFeeId
                }
            }
            console.log(data)
            //Call API to save
            promisedPosts.push(postManualFee(data))
        })

        Promise.all(promisedPosts)
            .then((res) => {
                console.log(res)

                const event = {
                    bucode: bucode,
                    sblu: sblu,
                    workOrder: workOrder,
                    vin: vin,
                    seller: sellerNumber,
                    buyer: buyerNumber
                }
                postFeeEvent(event)
                setToggle(!toggle)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const onRemove = () => {
        let removePromises = []
        manualFees.forEach((fee) => {
            removePromises.push(removeManualFee(fee.id))
        })

        Promise.all(removePromises)
            .then((res) => {
                console.log(res)

                const event = {
                    bucode: bucode,
                    sblu: sblu,
                    workOrder: workOrder,
                    vin: vin,
                    seller: sellerNumber,
                    buyer: buyerNumber
                }
                postFeeEvent(event)
                setToggle(!toggle)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    return (
        <>
            {show && (
                <>
                    {!toggle && (
                        <Button
                            onClick={() => {
                                setToggle(!toggle)
                            }}>
                            Manual Fee
                        </Button>
                    )}
                    {toggle && !manualLoading && (
                        <>
                            <Card
                                header={<h2>Manual Fees</h2>}
                                content={
                                    <>
                                        <PatErrorAlert
                                            error={validationError}
                                        />
                                        <PatErrorAlert error={manualError} />
                                        <FeeInputForm
                                            buyer={buyer}
                                            seller={seller}
                                            intBuy={intBuy}
                                            intSell={intSell}
                                            notes={notes}
                                            channel={channel}
                                            onChange={onChange}
                                        />
                                    </>
                                }
                                footer={
                                    <ManualFeeActions
                                        toggle={toggle}
                                        setToggle={setToggle}
                                        save={onSave}
                                        remove={onRemove}
                                    />
                                }
                            />
                        </>
                    )}
                </>
            )}
        </>
    )
}
