import { React, useState } from 'react'
import { RateCardSelector } from './ratecard/RateCardSelector'
import { isNumeric } from '../utils/commonValidations'
import { LocationComboBox } from './common/LocationComboBox'
import { SaleTypesComboBox } from './common/SaleTypesComboBox'
import { FeeTypesComboBox } from './common/FeeTypesComboBox'
import { Grid } from '@interstate/components/Grid'
import { Button } from '@interstate/components/Button'
import { Action } from '@interstate/components/Action'
import { TrashIcon } from '@interstate/components/Icons'
import { Typography } from '@interstate/components/Typography'
import { RateCardSearchSlideOut } from './slideouts/RateCardSearchSlideOut'
import { RateCardCreateSlideOut } from './slideouts/RateCardCreateSlideOut'

const mapRateCardToFeeStructure = (rateCard) => {
    // console.log('Mapping rate card to fee structure: ', rateCard)
    return {
        type: rateCard.type,
        salesPriceLimit: rateCard.salesPriceLimit,
        addFeesAmount: rateCard.addFeesAmount,
        feeUnit: rateCard.feeUnit,
        feeMaximum: rateCard.feeMaximum,
        feePercent: rateCard.feePercent,
        feeTiers: rateCard.priceTier.map((t, i) => ({
            lowLimit: t.lowLimit,
            highLimit:
                t.highLimit === 500000 && i === rateCard.priceTier.length
                    ? 999999
                    : t.highLimit,
            fee: t.fee
        }))
    }
}

const mapFlatRateToFeeStructure = (flatRateAmount) => {
    return {
        type: 'FlatFee',
        feeTiers: [
            {
                lowLimit: 1,
                highLimit: 999999,
                fee: parseInt(flatRateAmount)
            }
        ]
    }
}

export function SaleTypeFee({
    data,
    onDataChange,
    allRateCardsList,
    scheduleFeeType,
    stflIndex
}) {
    //ui state
    const [showRateCardSearchModal, setShowRateCardSearchModal] =
        useState(false)
    const [showRateCardCreateModal, setShowRateCardCreateModal] =
        useState(false)
    const [currentFeeOverrideIndex, setCurrentFeeOverrideIndex] = useState()

    //Can only override or exclude locations if this is an "ALL" entry
    const canOverrideOrExclude = data.auctionCodeList?.includes('ALL')

    // useEffect(() => {
    //     if (!data.auctionCodeList?.includes('ALL')) {
    //         data.overrides = null
    //         data.excludedAuctionList = null
    //         onDataChange(stflIndex, data)
    //     }
    // }, [stflIndex, onDataChange, data, canOverrideOrExclude])

    function onSaleTypeChange(saleType) {
        data = { ...data, saleType }
        onDataChange(stflIndex, data)
    }

    function onFeeCodeChange(feeCode) {
        data = { ...data, feeCode }
        onDataChange(stflIndex, data)
    }

    function onRateTypeChange(rateCardType, feeOverrideIndex) {
        if (feeOverrideIndex !== null && feeOverrideIndex !== undefined) {
            const overrides = [...data.overrides]
            overrides[feeOverrideIndex].rateCardType = rateCardType
            data = { ...data, overrides }
        } else {
            data = { ...data, rateCardType }
        }

        onDataChange(stflIndex, data)
    }

    function onFlatRateChange(flatRateAmount, feeOverrideIndex) {
        if (!isNumeric(flatRateAmount)) {
            return false
        }

        if (feeOverrideIndex !== null && feeOverrideIndex !== undefined) {
            const overrides = [...data.overrides]
            overrides[feeOverrideIndex] = {
                ...overrides[feeOverrideIndex],
                rateCardDesc: 'Flat Fee $' + flatRateAmount,
                rateCardId: null,
                feeStructure: mapFlatRateToFeeStructure(flatRateAmount)
            }
            data = { ...data, overrides }
        } else {
            data = {
                ...data,
                rateCardDesc: 'Flat Fee $' + flatRateAmount,
                rateCardId: null,
                feeStructure: mapFlatRateToFeeStructure(flatRateAmount)
            }
        }

        onDataChange(stflIndex, data)
    }

    function onAuctionSelect(selectedItems, feeOverrideIndex) {
        // console.log('onAuctionSelect', selectedItems)
        const auctionCodeList = selectedItems.map(
            (selectedItem) => selectedItem.value
        )

        if (feeOverrideIndex !== null && feeOverrideIndex !== undefined) {
            const overrides = [...data.overrides]
            overrides[feeOverrideIndex] = {
                ...overrides[feeOverrideIndex],
                auctionCodeList
            }
            data = { ...data, overrides }
        } else {
            data = { ...data, auctionCodeList }
        }

        onDataChange(stflIndex, data)
    }

    function onAuctionExclude(excludedItems) {
        // console.log('onAuctionExclude', excludedItems)
        const excludedAuctionList = excludedItems.map(
            (excludedItem) => excludedItem.value
        )
        data = { ...data, excludedAuctionList }
        onDataChange(stflIndex, data)
    }

    function onRateCardSelection(
        rateCard,
        feeOverrideIndex,
        createRateCardMode
    ) {
        // console.log(
        //     'Rate card selected ' +
        //         (feeOverrideIndex != null
        //             ? ` for override ${feeOverrideIndex}`
        //             : '') +
        //         ': ',
        //     rateCard
        // )
        // if the ratecard was newly created, then update it into allRateCardsList
        if (createRateCardMode) {
            // addToRateCardList(allRateCardsList, rateCard);
            allRateCardsList.push(rateCard)
        }

        if (feeOverrideIndex !== null && feeOverrideIndex !== undefined) {
            const overrides = [...data.overrides]
            overrides[feeOverrideIndex] = {
                ...overrides[feeOverrideIndex],
                rateCardId: rateCard.id,
                rateCardDesc: rateCard.description,
                feeStructure: mapRateCardToFeeStructure(rateCard)
            }
            data = { ...data, overrides }
        } else {
            data = {
                ...data,
                rateCardId: rateCard.id,
                rateCardDesc: rateCard.description,
                feeStructure: mapRateCardToFeeStructure(rateCard)
            }
        }

        setShowRateCardSearchModal(false)
        setShowRateCardCreateModal(false)

        onDataChange(stflIndex, data)
    }

    function openRateCardSearch(feeOverrideIndex) {
        setCurrentFeeOverrideIndex(feeOverrideIndex)
        toggleRateCardSearchModal()
    }

    function toggleRateCardSearchModal() {
        setShowRateCardCreateModal(false)
        if (showRateCardSearchModal) {
            setShowRateCardSearchModal(false)
        } else {
            setShowRateCardSearchModal(true)
        }
    }

    function toggleRateCardCreateModal() {
        setShowRateCardSearchModal(false)
        if (showRateCardCreateModal) {
            setShowRateCardCreateModal(false)
        } else {
            setShowRateCardCreateModal(true)
        }
    }

    function addOverride() {
        const overrides = [
            ...(data.overrides || []),
            { auctionCodeList: [], rateCardId: null, rateCardDesc: null }
        ]
        data = { ...data, overrides }
        onDataChange(stflIndex, data)
    }

    function getOverrideAuctions() {
        let overrideAuctions = []
        data.overrides?.forEach((override) => {
            overrideAuctions = overrideAuctions.concat(override.auctionCodeList)
        })
        data.excludedAuctionList?.forEach((excludedAuction) => {
            overrideAuctions.push(excludedAuction)
        })
        return overrideAuctions
    }

    function deleteSaleTypeOverride(stflIndex, feeOverrideIndex) {
        if (data.overrides?.length > 0) {
            data.overrides?.splice(feeOverrideIndex, 1)
        }
        onDataChange(stflIndex, data)
    }

    return (
        <>
            <Grid
                container
                columnSpacing={'15px'}
                data-testid={'container_SaleTypeFee_stflI_' + stflIndex}>
                <Grid
                    item
                    xs={4}
                    dataTestId={'container_Locations_stflI_' + stflIndex}>
                    <LocationComboBox
                        all
                        value={data.auctionCodeList}
                        auctionEditFilter={true}
                        dataTestId={'Locations_stflI_' + stflIndex}
                        onChange={(e) => {
                            const cur = data.auctionCodeList
                            if (cur.includes('ALL')) {
                                const newList = e.target.value.filter(
                                    (a) => a.value !== 'ALL'
                                )
                                onAuctionSelect(newList)
                            } else {
                                const newList = e.target.value.filter(
                                    (a) => a.value === 'ALL'
                                )
                                if (newList.length > 0) {
                                    onAuctionSelect(newList)
                                } else {
                                    onAuctionSelect(e.target.value)
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <SaleTypesComboBox
                        value={data.saleType}
                        onChange={(e) => onSaleTypeChange(e.target.value)}
                        required
                        data-testid={'SaleType_stflI_' + stflIndex}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FeeTypesComboBox
                        value={data.feeCode}
                        onChange={(e) => onFeeCodeChange(e.target.value)}
                        scheduleFeeType={scheduleFeeType}
                        required
                        dataTestId={'FeeType_stflI_' + stflIndex}
                    />
                </Grid>
                <Grid item xs={4}>
                    <RateCardSelector
                        rateCardId={data.rateCardId}
                        //TODO: rework to only use fee structure type?
                        rateCardType={
                            data.rateCardType || data.feeStructure?.type
                        }
                        rateCardDesc={data.rateCardDesc}
                        feeStructure={data.feeStructure}
                        openRateCardSearch={openRateCardSearch}
                        onRateTypeChange={onRateTypeChange}
                        onFlatRateChange={onFlatRateChange}
                        dataTestId={'FeeStructure_stflI_' + stflIndex}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                columnSpacing={'15px'}
                data-testid={'container_Exceptions_stflI_' + stflIndex}>
                {canOverrideOrExclude && (
                    <>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px',
                                borderBottom: '1px dashed #ccc;'
                            }}>
                            <Typography
                                variant="h5"
                                data-testid={
                                    'headline_Exceptions_stflI_' + stflIndex
                                }>
                                Exceptions
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {data.overrides &&
                                data.overrides.map(
                                    (saleTypeFeeOverride, feeOverrideIndex) => {
                                        return (
                                            <div
                                                key={feeOverrideIndex}
                                                data-maptype="ferdI"
                                                data-mapindex={
                                                    feeOverrideIndex
                                                }>
                                                <Grid
                                                    container
                                                    columnSpacing={'15px'}
                                                    data-testid={
                                                        'container_ExceptionItem_stflI_' +
                                                        stflIndex +
                                                        '_ferdI_' +
                                                        feeOverrideIndex
                                                    }>
                                                    <Grid item xs={6}>
                                                        <LocationComboBox
                                                            value={
                                                                saleTypeFeeOverride.auctionCodeList
                                                            }
                                                            onChange={(e) => {
                                                                onAuctionSelect(
                                                                    e.target
                                                                        .value,
                                                                    feeOverrideIndex
                                                                )
                                                            }}
                                                            filterOut={getOverrideAuctions()}
                                                            dataTestId={
                                                                'ExceptionLocations_stflI_' +
                                                                stflIndex +
                                                                '_ferdI_' +
                                                                feeOverrideIndex
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <RateCardSelector
                                                            rateCardId={
                                                                saleTypeFeeOverride.rateCardId
                                                            }
                                                            //TODO: rework to only use fee structure type?
                                                            rateCardType={
                                                                saleTypeFeeOverride.rateCardType ||
                                                                saleTypeFeeOverride
                                                                    .feeStructure
                                                                    ?.type
                                                            }
                                                            rateCardDesc={
                                                                saleTypeFeeOverride.rateCardDesc
                                                            }
                                                            feeStructure={
                                                                saleTypeFeeOverride.feeStructure
                                                            }
                                                            openRateCardSearch={
                                                                openRateCardSearch
                                                            }
                                                            onRateTypeChange={
                                                                onRateTypeChange
                                                            }
                                                            onFlatRateChange={
                                                                onFlatRateChange
                                                            }
                                                            feeOverrideIndex={
                                                                feeOverrideIndex
                                                            }
                                                            dataTestId={
                                                                '_stflI_' +
                                                                stflIndex +
                                                                '_ferdI_' +
                                                                feeOverrideIndex
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'flex-end'
                                                        }}
                                                        xs={1}
                                                        container>
                                                        <Action
                                                            marginTop="20px"
                                                            data-testid={
                                                                'button_RemoveExceptionItem_stflI_' +
                                                                stflIndex +
                                                                '_ferdI_' +
                                                                feeOverrideIndex
                                                            }
                                                            size="sm"
                                                            actionIcon={{
                                                                icon: (
                                                                    <TrashIcon />
                                                                ),
                                                                start: true
                                                            }}
                                                            onClick={(e) =>
                                                                deleteSaleTypeOverride(
                                                                    stflIndex,
                                                                    feeOverrideIndex
                                                                )
                                                            }>
                                                            Delete
                                                        </Action>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )
                                    }
                                )}
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                            <Button
                                buttonStyle="secondary"
                                onClick={() => addOverride()}
                                data-testid={
                                    'button_AddException_stflI_' + stflIndex
                                }>
                                Add Exception
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px',
                                borderBottom: '1px dashed #ccc;'
                            }}
                            data-testid={
                                'container_ExcludedLocations_stflI_' + stflIndex
                            }>
                            <Typography
                                variant="h5"
                                data-testid={
                                    'headline_ExcludedLocations_stflI_' +
                                    stflIndex
                                }>
                                Excluded Locations
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LocationComboBox
                                value={data.excludedAuctionList}
                                dataTestId={
                                    'ExcludedLocations_stflI_' + stflIndex
                                }
                                onChange={(e) => {
                                    onAuctionExclude(e.target.value)
                                }}
                                filterOut={getOverrideAuctions()}
                            />
                        </Grid>
                    </>
                )}
            </Grid>

            <RateCardSearchSlideOut
                show={showRateCardSearchModal}
                close={toggleRateCardSearchModal}
                toggle={toggleRateCardCreateModal}
                onRateCardSelection={onRateCardSelection}
                currentFeeOverrideIndex={currentFeeOverrideIndex}
                allRateCardsList={allRateCardsList}
                moduleid={'_stflI_' + stflIndex}
            />

            <RateCardCreateSlideOut
                show={showRateCardCreateModal}
                close={toggleRateCardCreateModal}
                toggle={toggleRateCardCreateModal}
                currentFeeOverrideIndex={currentFeeOverrideIndex}
                onRateCardSelection={onRateCardSelection}
                allRateCardsList={allRateCardsList}
                moduleid={'_stflI_' + stflIndex}
            />
        </>
    )
}
