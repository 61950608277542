import React from 'react'
import { dollarsFull } from '../../utils/Formatters'
import { RateCardOnTooltip } from '../ratecard/RateCardOnTooltip'
import { InfoIcon } from '../Icons'
import { Tooltip } from '../ToolTip'
// import { FEE_FLAT_TIERED } from '../../resources/enum.constants'

export function DisplayFeeTierAmount({ feeScheduleFlat, saleTypeFee }) {
    let feeStructure = null
    if (feeScheduleFlat) {
        feeStructure = feeScheduleFlat.feeStructure
    } else if (saleTypeFee) {
        feeStructure = saleTypeFee.feeStructure
    }
    function displayFeeAmount() {
        if (feeStructure) {
            let tierCount = feeStructure.feeTiers.length
            if (
                feeStructure.feeTiers?.length === 1 &&
                !feeStructure.feeUnit &&
                !feeStructure.feePercent
            ) {
                return (
                    <div>
                        <span>
                            {dollarsFull.format(feeStructure.feeTiers[0].fee)}
                        </span>
                    </div>
                )
            } else {
                return (
                    <Tooltip
                        content={
                            <RateCardOnTooltip feeStructure={feeStructure} />
                        }
                        direction="bottom">
                        <span>
                            {tierCount} Tier(s) &nbsp;
                            <InfoIcon />
                        </span>{' '}
                        &nbsp;
                    </Tooltip>
                )
            }
        }
        return null
    }

    return <>{displayFeeAmount()}</>
}
