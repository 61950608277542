import { Table } from '@interstate/components/Table'
import React, { useEffect, useState } from 'react'
import { renderDateFromIsoString } from '../../utils/Formatters'
import { useAuditSearch } from '../../service/auditService'

export function CalculatedFeeTable({ sblu, bucode, refresh }) {
    const [query, setQuery] = useState(null)
    const {
        value: searchResults,
        loading: searchResultsLoading,
        error: searchResultsError
    } = useAuditSearch(query)

    useEffect(() => {
        let q = {
            testSource: 'vehicle-fee-processor-lambda',
            sblu: sblu,
            locationId: [bucode],
            refresh: refresh
        }
        setQuery(q)
    }, [sblu, bucode, refresh])
    return (
        <>
            <h2>Calculated Fees</h2>
            {searchResultsError && (
                <div>
                    <h3>Error</h3>
                    <p>{searchResultsError.message}</p>
                </div>
            )}
            {!searchResultsLoading && (
                <Table
                    tableLayout={'auto'}
                    columns={[
                        {
                            title: 'Fee Category',
                            render: (item) => <span>{item.feeCategory}</span>
                        },
                        {
                            title: 'Sale Type',
                            render: (item) => <span>{item.saleType}</span>
                        },
                        {
                            title: 'Fee Schedule Type',
                            render: (item) => <span>{item.contractType}</span>
                        },
                        {
                            title: 'Fee Type',
                            render: (item) => <span>{item.feeType}</span>
                        },
                        {
                            title: 'Pat Fee',
                            render: (item) => (
                                <span>{item.patCalculatedFee}</span>
                            )
                        },
                        {
                            title: 'AS400 Fee',
                            render: (item) => (
                                <span>{item.as400CalculatedFee}</span>
                            )
                        },
                        {
                            title: 'FeeID',
                            render: (item) => (
                                <span>{item.feeSchedulePartId}</span>
                            )
                        },
                        {
                            title: 'Write Back',
                            render: (item) => (
                                <span>{item.writeback ? 'true' : 'false'}</span>
                            )
                        },
                        {
                            title: 'Fee Changed',
                            render: (item) => (
                                <span>
                                    {item.feeChanged ? 'true' : 'false'}
                                </span>
                            )
                        },
                        {
                            title: 'Created On',
                            render: (item) => (
                                <span>
                                    {renderDateFromIsoString(
                                        item.created,
                                        'MM/DD/YYYY'
                                    )}
                                </span>
                            )
                        }
                    ]}
                    data={searchResults}
                />
            )}
        </>
    )
}
